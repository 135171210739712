import React, {Suspense, lazy, Fragment} from "react";
import Loader from "react-loaders";
import {Route} from "react-router-dom";
import { ToastContainer } from "react-toastify";

const Forbidden = lazy(() => import("../../features/forbidden"));
const Dashboard = lazy(() => import("../../features/dashboard"));
const Notifications = lazy(() => import("../../features/notifications"));
const Units = lazy(() => import("../../features/units/index"));
const Estimations = lazy(() => import("../../features/estimations/index"));
const Reservations = lazy(() => import("../../features/reservations/index"));
const StockControl = lazy(() => import("../../features/stock-control/index"));
const MapView = lazy(() => import("../../features/units/MapView/index"));
const Leads = lazy(() => import("../../features/leads/index"));

const AppMain = () => {

  const fallbackMessage = () => {
    return (<div className="loader-container">
      <div className="loader-container-inner">
        <div className="text-center">
          <Loader type="ball-pulse-rise" active/>
        </div>
        <h6 className="mt-5">
          Cargando componentes
          <small>Aguarde un momento por favor.</small>
        </h6>
      </div>
    </div>)
  }

  return (
    <Fragment>
      <Suspense fallback={fallbackMessage()}>
        <Route exact path="/" component={Dashboard}/>
      </Suspense>

      <Suspense fallback={fallbackMessage()}>
        <Route exact path="/notifications" component={Notifications}/>
      </Suspense>

      <Suspense fallback={fallbackMessage()}>
        <Route exact path="/units" component={Units}/>
      </Suspense>

      <Suspense fallback={fallbackMessage()}>
        <Route exact path="/estimations" component={Estimations}/>
      </Suspense>

      <Suspense fallback={fallbackMessage()}>
        <Route exact path="/reservations" component={Reservations}/>
      </Suspense>

      <Suspense fallback={fallbackMessage()}>
        <Route exact path="/stock-control" component={StockControl}/>
      </Suspense>

      <Suspense fallback={fallbackMessage()}>
        <Route exact path="/forbidden" component={Forbidden}/>
      </Suspense>

      <Suspense fallback={fallbackMessage()}>
        <Route exact path="/map-view" component={MapView}/>
      </Suspense>

      <Suspense fallback={fallbackMessage()}>
        <Route exact path="/leads" component={Leads}/>
      </Suspense>

      <ToastContainer hideProgressBar/>
    </Fragment>
  )
};

export default AppMain;
