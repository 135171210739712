import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios";

const initialState = {
  loading: false,
  error: null,
  validationErrors: {},
  lastCustomerCreated: null
};

export const createCustomerRequest = createAsyncThunk('createCustomer/createCustomerRequest',
  async ({values, accessToken}, {rejectWithValue}) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + `/customers`,
        values,
        {
          headers: {
            Authorization: "Bearer " + accessToken
          }
        });

      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }

      return rejectWithValue(err.response.data)
    }
  },)

const createCustomerSlice = createSlice({
  name: 'createCustomer',
  initialState,
  reducers: {
    confirmValidationErrors(state, action) {
      state.error = null
    }
  },
  extraReducers: {
    [createCustomerRequest.pending]: (state, action) => {
      state.loading = true
      state.error = null
      state.validationErrors = []
    },
    [createCustomerRequest.fulfilled]: (state, action) => {
      state.loading = false
      state.lastCustomerCreated = action.payload.data
    },
    [createCustomerRequest.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload.message
      state.validationErrors = action.payload.errors
    }
  },
})

export const {
  confirmValidationErrors
} = createCustomerSlice.actions

export default createCustomerSlice.reducer
