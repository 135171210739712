import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
  loading: false,
  data: {
    type: 'FeatureCollection',
    features: null
  },
  params: {
    page: 1,
    sizePerPage: 100,
    filters: [],
    sortField: undefined,
    sortOrder: undefined
  },
  error: null
};

export const fetchMapView = createAsyncThunk('mapView/fetchMapView',
  async ({params, accessToken}, {rejectWithValue}) => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + '/units/map-view/json-features', {
        headers: {
          Authorization: "Bearer " + accessToken
        },
        params
      });

      return {
        response: response.data,
        params
      };
    } catch (err) {
      if (!err.response) {
        throw err
      }

      return rejectWithValue(err.response.data)
    }
  })

const mapViewSlice = createSlice({
  name: 'mapView',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchMapView.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [fetchMapView.fulfilled]: (state, action) => {
      state.loading = false
      //state.params = action.payload.params
      state.data = action.payload.response
    },
    [fetchMapView.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
  },
})

export default mapViewSlice.reducer
