import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
  loading: false,
  data: {
    total: 0,
    data: [],
    miniDashboard: {
      calculated: 0,
      deposit: 0,
      estimated: 0
    }
  },
  params: {
    page: 1,
    sizePerPage: 10,
    filters: [],
    sortField: undefined,
    sortOrder: undefined
  },
  error: null,
  entrepreneurshipFilter: {
    loading: false,
    error: null,
    data: []
  },
  productFilter: {
    loading: false,
    error: null,
    data: []
  },
  productTypeFilter: {
    loading: false,
    error: null,
    data: []
  },
  currentEstimation: {
    loading: false,
    error: null,
    data: null
  },
  updateEstimation: {
    loading: false,
    error: null,
  },
  uploadDepositFile: {
    loading: false,
    url: null,
    error: null,
  },
  uploadBuyingOfferFile: {
    loading: false,
    url: null,
    error: null,
  },
  requestExtension: {
    loading: false,
    error: null,
  },
  finishEstimation: {
    loading: false,
    error: null,
  },
  reserveEstimation: {
    loading: false,
    error: null,
  },
  sendEmail: {
    loading: false,
    error: null,
  },
  takeActionModal: {
    show: false,
    unit: null
  }
};

export const fetchUnits = createAsyncThunk(
  'estimations/fetchUnits',
  async ({params, accessToken}) => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + '/estimations', {
        headers: {
          Authorization: "Bearer " + accessToken
        },
        params
      });

      return {
        response: response.data,
        params
      };
    } catch (err) {
      const errorMessage = {
        name: "fetchUnits error",
        message: err.response.data.message
      };
      throw errorMessage;
    }
  })

export const fetchEntrepreneurshipFilter = createAsyncThunk(
  'estimations/fetchEntrepreneurshipFilter',
  async ({accessToken}) => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + '/entrepreneurships', {
        headers: {
          Authorization: "Bearer " + accessToken
        }
      });

      return response.data;
    } catch (err) {
      const errorMessage = {
        name: "fetchEntrepreneurshipFilter error",
        message: err.response.data.message
      };
      throw errorMessage;
    }
  })

export const fetchProductFilter = createAsyncThunk(
  'estimations/fetchProductFilter',
  async ({
           entrepreneurship_id,
           accessToken
         }) => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + '/products', {
        headers: {
          Authorization: "Bearer " + accessToken
        },
        params: {
          entrepreneurship_id
        }
      });

      return response.data;
    } catch (err) {
      const errorMessage = {
        name: "fetchProductFilter error",
        message: err.response.data.message
      };
      throw errorMessage;
    }
  })

export const fetchProductTypeFilter = createAsyncThunk(
  'estimations/fetchProductTypeFilter',
  async ({accessToken}) => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + '/product-types', {
        headers: {
          Authorization: "Bearer " + accessToken
        }
      });

      return response.data;
    } catch (err) {
      const errorMessage = {
        name: "fetchProductTypeFilter error",
        message: err.response.data.message
      };
      throw errorMessage;
    }
  })

export const fetchEstimation = createAsyncThunk(
  'estimations/fetchEstimation',
  async ({accessToken, saleEstimationId}
  ) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + '/estimations/' + saleEstimationId,
        {
          headers: {
            Authorization: "Bearer " + accessToken
          }
        });

      return response.data;
    } catch (err) {
      const errorMessage = {
        name: "fetchEstimation error",
        message: err.response.message
      };
      throw errorMessage;
    }
  })

export const updateEstimationRequest = createAsyncThunk('editEstimation/updateEstimation',
  async ({id, values, accessToken}, {rejectWithValue}) => {
    try {
      const response = await axios.patch(
        process.env.REACT_APP_API_URL + `/estimations/${id}`,
        values,
        {
          headers: {
            Authorization: "Bearer " + accessToken
          }
        })

      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }

      return rejectWithValue(err.response.data)
    }
  })

export const uploadDepositFileRequest = createAsyncThunk('editEstimation/uploadDepositFile',
  async ({id, file, accessToken}, {rejectWithValue}) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + `/estimations/${id}/deposit/file`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "multipart/form-data"
          }
        })

      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }

      return rejectWithValue(err.response.data)
    }
  })

export const uploadBuyingOfferFileRequest = createAsyncThunk('editEstimation/uploadBuyingOfferFile',
  async ({id, file, accessToken}, {rejectWithValue}) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + `/estimations/${id}/buying-offer/file`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "multipart/form-data"
          }
        })

      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }

      return rejectWithValue(err.response.data)
    }
  })

export const requestExtensionRequest = createAsyncThunk('editEstimation/requestExtension',
  async ({id, saleEstimationId, proposedDate, comment, accessToken}, {rejectWithValue}) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + `/estimations/${saleEstimationId}/request-extension`,
        {
          proposed_date: proposedDate,
          comment: comment,
        },
        {
          headers: {
            Authorization: "Bearer " + accessToken
          }
        })

      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }

      return rejectWithValue(err.response.data)
    }
  })

export const finishEstimationRequest = createAsyncThunk('editEstimation/finishEstimation',
  async ({id, saleEstimationId, reason, comment, accessToken}, {rejectWithValue}) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + `/estimations/${saleEstimationId}/finish`,
        {
          reason: reason,
          comment: comment
        },
        {
          headers: {
            Authorization: "Bearer " + accessToken
          }
        })

      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }

      return rejectWithValue(err.response.data)
    }
  })

export const reserveEstimationRequest = createAsyncThunk('editEstimation/reserveEstimation',
  async ({id, accessToken}, {rejectWithValue}) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + `/estimations/${id}/reserve`,
        {},
        {
          headers: {
            Authorization: "Bearer " + accessToken
          }
        })

      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }

      return rejectWithValue(err.response.data)
    }
  })

export const sendEmailRequest = createAsyncThunk('editEstimation/sendEmail',
  async ({id, saleEstimationId, reason, comment, accessToken}, {rejectWithValue}) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + `/estimations/email/send`,
        {
          estimation_id: saleEstimationId
        },
        {
          headers: {
            Authorization: "Bearer " + accessToken
          }
        })

      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }

      return rejectWithValue(err.response.data)
    }
  })

const estimationSlice = createSlice({
  name: 'estimations',
  initialState,
  reducers: {
    unloadCurrentEstimation(state, action) {
      state.currentEstimation.loading = false
      state.currentEstimation.error = null
      state.currentEstimation.data = null
    },
    toggleTakeActionModal(state, action) {
      state.takeActionModal.show = action.payload.show
      state.takeActionModal.unit = action.payload.unit
    },
    dismissReserveError(state, action) {
      state.reserveEstimation.error = null
    }
  },
  extraReducers: {
    [fetchUnits.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [fetchUnits.fulfilled]: (state, action) => {
      state.loading = false
      state.params = action.payload.params
      state.data.total = action.payload.response.total
      state.data.data = action.payload.response.data
      state.data.miniDashboard = action.payload.response.mini_dashboard
    },
    [fetchUnits.rejected]: (state, action) => {
      state.loading = false
      state.error = action.error.message
    },

    [fetchEntrepreneurshipFilter.pending]: (state, action) => {
      state.entrepreneurshipFilter.loading = true
      state.entrepreneurshipFilter.error = null
    },
    [fetchEntrepreneurshipFilter.fulfilled]: (state, action) => {
      state.entrepreneurshipFilter.loading = false
      state.entrepreneurshipFilter.data = action.payload.data
    },
    [fetchEntrepreneurshipFilter.rejected]: (state, action) => {
      state.entrepreneurshipFilter.loading = false
      state.entrepreneurshipFilter.error = action.error.message
    },

    [fetchProductFilter.pending]: (state, action) => {
      state.productFilter.loading = true
      state.productFilter.error = null
    },
    [fetchProductFilter.fulfilled]: (state, action) => {
      state.productFilter.loading = false
      state.productFilter.data = action.payload.data
    },
    [fetchProductFilter.rejected]: (state, action) => {
      state.productFilter.loading = false
      state.productFilter.error = action.error.message
    },

    [fetchProductTypeFilter.pending]: (state, action) => {
      state.productTypeFilter.loading = true
      state.productTypeFilter.error = null
    },
    [fetchProductTypeFilter.fulfilled]: (state, action) => {
      state.productTypeFilter.loading = false
      state.productTypeFilter.data = action.payload.data
    },
    [fetchProductTypeFilter.rejected]: (state, action) => {
      state.productTypeFilter.loading = false
      state.productTypeFilter.error = action.error.message
    },

    [fetchEstimation.pending]: (state, action) => {
      state.currentEstimation.loading = true
      state.currentEstimation.error = null
    },
    [fetchEstimation.fulfilled]: (state, action) => {
      state.currentEstimation.loading = false
      state.currentEstimation.data = action.payload
      state.uploadDepositFile.url = action.payload.deposit?.fileUrl
      state.uploadBuyingOfferFile.url = action.payload.buying_offer_url
    },
    [fetchEstimation.rejected]: (state, action) => {
      state.currentEstimation.loading = false
      state.currentEstimation.error = action.error.message
    },

    [updateEstimationRequest.pending]: (state, action) => {
      state.updateEstimation.loading = true
      state.updateEstimation.error = null
    },
    [updateEstimationRequest.fulfilled]: (state, action) => {
      state.updateEstimation.loading = false
    },
    [updateEstimationRequest.rejected]: (state, action) => {
      state.updateEstimation.loading = false
      state.updateEstimation.error = action.error.message
    },

    [uploadDepositFileRequest.pending]: (state, action) => {
      state.uploadDepositFile.loading = true
      state.uploadDepositFile.error = null
    },
    [uploadDepositFileRequest.fulfilled]: (state, action) => {
      state.uploadDepositFile.loading = false
      state.uploadDepositFile.url = action.payload.url
      state.uploadDepositFile.error = null
    },
    [uploadDepositFileRequest.rejected]: (state, action) => {
      state.uploadDepositFile.loading = false
      state.uploadDepositFile.error = action.error.message
    },

    [uploadBuyingOfferFileRequest.pending]: (state, action) => {
      state.uploadBuyingOfferFile.loading = true
      state.uploadBuyingOfferFile.error = null
    },
    [uploadBuyingOfferFileRequest.fulfilled]: (state, action) => {
      state.uploadBuyingOfferFile.loading = false
      state.uploadBuyingOfferFile.url = action.payload.url
      state.uploadBuyingOfferFile.error = null
    },
    [uploadBuyingOfferFileRequest.rejected]: (state, action) => {
      state.uploadBuyingOfferFile.loading = false
      state.uploadBuyingOfferFile.error = action.error.message
    },

    [requestExtensionRequest.pending]: (state, action) => {
      state.requestExtension.loading = true
      state.requestExtension.error = null
    },
    [requestExtensionRequest.fulfilled]: (state, action) => {
      state.requestExtension.loading = false
    },
    [requestExtensionRequest.rejected]: (state, action) => {
      state.requestExtension.loading = false
      state.requestExtension.error = action.error.message
    },

    [finishEstimationRequest.pending]: (state, action) => {
      state.finishEstimation.loading = true
      state.finishEstimation.error = null
    },
    [finishEstimationRequest.fulfilled]: (state, action) => {
      state.finishEstimation.loading = false
    },
    [finishEstimationRequest.rejected]: (state, action) => {
      state.finishEstimation.loading = false
      state.finishEstimation.error = action.error.message
    },

    [reserveEstimationRequest.pending]: (state, action) => {
      state.reserveEstimation.loading = true
      state.reserveEstimation.error = null
    },
    [reserveEstimationRequest.fulfilled]: (state, action) => {
      state.reserveEstimation.loading = false
      state.reserveEstimation.data = action.payload
    },
    [reserveEstimationRequest.rejected]: (state, action) => {
      state.reserveEstimation.loading = false
      state.reserveEstimation.error = action.payload
    },

    [sendEmailRequest.pending]: (state, action) => {
      state.sendEmail.loading = true
      state.sendEmail.error = null
    },
    [sendEmailRequest.fulfilled]: (state, action) => {
      state.sendEmail.loading = false
    },
    [sendEmailRequest.rejected]: (state, action) => {
      state.sendEmail.loading = false
      state.sendEmail.error = action.error.message
    }
  },
})

export const {unloadCurrentEstimation, toggleTakeActionModal, dismissReserveError} = estimationSlice.actions

export default estimationSlice.reducer

export const selectAllUnits = (state) => {
  return state.estimations
}
