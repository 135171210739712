import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
  loading: false,
  data: null,
  error: null,
};

export const request = createAsyncThunk(
  'requestEstimationAuthorization/request',
  async ({id, proposedDate, comment, accessToken}, {rejectWithValue}) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/units/${id}/request-estimation-authorization`,
        {
          proposed_date: proposedDate,
          comment,
        },
        {
          headers: {
            Authorization: "Bearer " + accessToken
          }
        })

      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }

      return rejectWithValue(err.response.data)
    }
  })

const requestEstimationAuthorizationSlice = createSlice({
  name: 'requestEstimationAuthorization',
  initialState,
  reducers: {},
  extraReducers: {
    [request.pending]: (state, action) => {
      state.loading = true
      state.data = null
      state.error = null
    },
    [request.fulfilled]: (state, action) => {
      state.loading = false
      state.data = action.payload
      state.error = null
    },
    [request.rejected]: (state, action) => {
      state.loading = false
      state.error = action.error.message
    },
  },
})

export default requestEstimationAuthorizationSlice.reducer

