import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios";

const initialState = {
  loading: false,
  error: null,
  validationErrors: {},
};

export const freeUnitRequest = createAsyncThunk('freeUnit/freeUnitRequest',
  async ({id, accessToken}, {rejectWithValue}) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + `/estimations/${id}/free`,
        {},
        {
          headers: {
            Authorization: "Bearer " + accessToken
          }
        });

      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }

      return rejectWithValue(err.response.data)
    }
  },)

const freeUnitSlice = createSlice({
  name: 'freeUnit',
  initialState,
  reducers: {
    confirmValidationErrors(state, action) {
      state.error = null
    }
  },
  extraReducers: {
    [freeUnitRequest.pending]: (state, action) => {
      state.loading = true
      state.error = null
      state.validationErrors = []
    },
    [freeUnitRequest.fulfilled]: (state, action) => {
      state.loading = false
    },
    [freeUnitRequest.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload.message
      state.validationErrors = action.payload.errors
    }
  },
})

export const {
  confirmValidationErrors,
} = freeUnitSlice.actions

export default freeUnitSlice.reducer
