import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
  loading: false,
  data: null,
  error: null,
};

export const request = createAsyncThunk(
  'LeadsChangeStatus/request',
  async ({id, status, accessToken}, {rejectWithValue}) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/customers/${id}`,
        {
          status,
        },
        {
          headers: {
            Authorization: "Bearer " + accessToken
          }
        })

      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }

      return rejectWithValue(err.response.data)
    }
  })

const changeLeadStatusSlice = createSlice({
  name: 'leadsChangeStatus',
  initialState,
  reducers: {},
  extraReducers: {
    [request.pending]: (state, action) => {
      state.loading = true
      state.data = null
      state.error = null
    },
    [request.fulfilled]: (state, action) => {
      state.loading = false
      state.data = action.payload
      state.error = null
    },
    [request.rejected]: (state, action) => {
      state.loading = false
      state.error = action.error.message
    },
  },
})

export default changeLeadStatusSlice.reducer

