import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios";

const initialState = {
  loading: false,
  error: null,
  validationErrors: {},
  //lastCustomerCreated: null
};

export const loadCustomerRequest = createAsyncThunk('editCustomer/loadCustomerRequest',
  async ({id, accessToken}, {rejectWithValue}) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/customers/${id}`,
        {
          headers: {
            Authorization: "Bearer " + accessToken
          }
        });

      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }

      return rejectWithValue(err.response.data)
    }
  },)

export const updateCustomerRequest = createAsyncThunk('editCustomer/updateCustomerRequest',
  async ({id, values, accessToken}, {rejectWithValue}) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/customers/${id}`,
        values,
        {
          headers: {
            Authorization: "Bearer " + accessToken
          }
        });

      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }

      return rejectWithValue(err.response.data)
    }
  },)

const editCustomerSlice = createSlice({
  name: 'editCustomer',
  initialState,
  reducers: {
    confirmValidationErrors(state, action) {
      state.error = null
    }
  },
  extraReducers: {
    [loadCustomerRequest.pending]: (state, action) => {
      state.loading = true
      state.error = null
      state.validationErrors = []
    },
    [loadCustomerRequest.fulfilled]: (state, action) => {
      state.loading = false
      //state.lastCustomerCreated = action.payload.data
    },
    [loadCustomerRequest.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload.message
      state.validationErrors = action.payload.errors
    },

    [updateCustomerRequest.pending]: (state, action) => {
      state.loading = true
      state.error = null
      state.validationErrors = []
    },
    [updateCustomerRequest.fulfilled]: (state, action) => {
      state.loading = false
      //state.lastCustomerCreated = action.payload.data
    },
    [updateCustomerRequest.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload.message
      state.validationErrors = action.payload.errors
    }
  },
})

export const {
  confirmValidationErrors
} = editCustomerSlice.actions

export default editCustomerSlice.reducer
