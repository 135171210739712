import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  loading: false,
  error: null,
  validationErrors: {},
  createCustomerModal: {
    show: false
  }
};

export const takeUnitRequest = createAsyncThunk('takeUnit/takeUnitRequest',
  async ({id, values, accessToken}, {rejectWithValue}) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + `/units/${id}/take`,
        values,
        {
          headers: {
            Authorization: "Bearer " + accessToken
          }
        });

      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }

      return rejectWithValue(err.response.data)
    }
  })

const takeUnitSlice = createSlice({
  name: 'takeUnit',
  initialState,
  reducers: {
    confirmValidationErrors(state, action) {
      state.error = null
    },
    toggleCreateCustomerModal(state, action) {
      state.createCustomerModal.show = action.payload.show
    },
    reset(state, action) {
      state.validationErrors = {}
    }
  },
  extraReducers: {
    [takeUnitRequest.pending]: (state, action) => {
      state.loading = true
      state.error = null
      state.validationErrors = []
      state.success = false
    },
    [takeUnitRequest.fulfilled]: (state, action) => {
      state.loading = false
      state.customer = null
      state.seller = null
      state.expiryDate = (new Date()).getTime() + 15 * 86400000
      state.comment = ''
    },
    [takeUnitRequest.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload.message
      state.validationErrors = action.payload.errors
    }
  },
})

export const {
  confirmValidationErrors,
  toggleCreateCustomerModal,
  reset
} = takeUnitSlice.actions

export default takeUnitSlice.reducer
