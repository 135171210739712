import "./polyfills";
import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";

import {HashRouter as Router} from "react-router-dom";
import "./assets/base.scss";
import App from "./App";
import store from "./config/store";
import {Provider} from "react-redux";
import Auth0ProviderWithHistory from "./auth/auth0-provider-with-history";

const rootElement = document.getElementById("root");

const renderApp = (Component) => {
    ReactDOM.render(
        <Provider store={store}>
            <Router>
                <Auth0ProviderWithHistory>
                    <Component/>
                </Auth0ProviderWithHistory>
            </Router>
        </Provider>,
        rootElement
    );
};

renderApp(App);

if (module.hot) {
    module.hot.accept("./App", () => {
        const NextApp = require("./App").default;
        renderApp(NextApp);
    });
}
serviceWorker.unregister();
