import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
  loading: false,
  data: {
    total: 0,
    data: [],
  },
  params: {
    page: 1,
    sizePerPage: 100,
    filters: [],
    sortField: undefined,
    sortOrder: undefined
  },
  error: null
};

export const fetchPendingEstimations = createAsyncThunk('estimations/fetchPending', async ({params, accessToken}) => {
  try {
    const response = await axios.get(process.env.REACT_APP_API_URL + '/estimations/pending', {
      headers: {
        Authorization: "Bearer " + accessToken
      },
      params
    });

    return {
      response: response.data,
      params
    };
  } catch (err) {
    const errorMessage = {
      name: "fetchPendingEstimations error",
      message: err.response.data.message
    };
    throw errorMessage;
  }
})

const pendingEstimationsSlice = createSlice({
  name: 'pendingEstimations',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchPendingEstimations.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [fetchPendingEstimations.fulfilled]: (state, action) => {
      state.loading = false
      state.params = action.payload.params
      state.data.total = action.payload.response.total
      state.data.data = action.payload.response.data
    },
    [fetchPendingEstimations.rejected]: (state, action) => {
      state.loading = false
      state.error = action.error.message
    },
  },
})

export default pendingEstimationsSlice.reducer

export const selectAllPendingEstimations = (state) => {
  return state.pendingEstimations
}
