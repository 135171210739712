import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  loading: false,
  error: null,
  validationErrors: {}
}

export const addCommentRequest = createAsyncThunk('editEstimation/addCommentRequest',
  async ({saleEstimationId, comment, accessToken}, {rejectWithValue}) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + `/estimations/${saleEstimationId}/comments`,
        {
          comment
        },
        {
          headers: {
            Authorization: "Bearer " + accessToken
          }
        })

      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }

      return rejectWithValue(err.response.data)
    }
  })

const addEstimationCommentSlice = createSlice({
  name: 'addComment',
  initialState,
  reducers: {
    reset(state, action) {
      state.validationErrors = {}
    }
  },
  extraReducers: {
    [addCommentRequest.pending]: (state, action) => {
      state.loading = true
      state.error = null
      state.validationErrors = []
      state.success = false
    },
    [addCommentRequest.fulfilled]: (state, action) => {
      state.loading = false
      state.customer = null
      state.unit = null
      state.comment = ''
    },
    [addCommentRequest.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload.message
      state.validationErrors = action.payload.errors
    }
  },
})

export const {reset} = addEstimationCommentSlice.actions

export default addEstimationCommentSlice.reducer
