import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
  loading: false,
  data: {
    total: 0,
    data: [],
  },
  params: {
    query: null
  },
  error: null
};

export const fetchSellers = createAsyncThunk('sellersApi/fetchSellers', async ({params, accessToken}) => {
  try {
    const response = await axios.get(process.env.REACT_APP_API_URL + '/sellers', {
      headers: {
        Authorization: "Bearer " + accessToken
      },
      params
    });

    return {
      response: response.data,
      params
    };
  } catch (err) {
    const errorMessage = {
      name: "fetchSellers error",
      message: err.response.data.message
    };
    throw errorMessage;
  }
})

const sellerApiSlice = createSlice({
  name: 'sellersApi',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchSellers.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [fetchSellers.fulfilled]: (state, action) => {
      state.loading = false
      state.params = action.payload.params
      state.data.total = action.payload.response.total
      state.data.data = action.payload.response.data
    },
    [fetchSellers.rejected]: (state, action) => {
      state.loading = false
      state.error = action.error.message
    }
  },
})

export default sellerApiSlice.reducer

export const selectAllSellers = (state) => {
  return state.sellersApi.data.data
}
