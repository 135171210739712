import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios";

const initialState = {
  loading: false,
  error: null,
  validationErrors: {},
};

export const observationRequest = createAsyncThunk('reservations/Observation',
  async ({id, type, comment, accessToken}, {rejectWithValue}) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + `/reservations/${id}/observation`,
        {
          type,
          comment
        },
        {
          headers: {
            Authorization: "Bearer " + accessToken
          }
        });

      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }

      return rejectWithValue(err.response.data)
    }
  },)

const observationSlice = createSlice({
  name: 'observation',
  initialState,
  reducers: {
    confirmValidationErrors(state, action) {
      state.error = null
    }
  },
  extraReducers: {
    [observationRequest.pending]: (state, action) => {
      state.loading = true
      state.error = null
      state.validationErrors = []
    },
    [observationRequest.fulfilled]: (state, action) => {
      state.loading = false
    },
    [observationRequest.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload.message
      state.validationErrors = action.payload.errors
    }
  },
})

export const {
  confirmValidationErrors,
} = observationSlice.actions

export default observationSlice.reducer
