import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
  loading: false,
  data: {
    total: 0,
    data: [],
  },
  params: {
    page: 1,
    sizePerPage: 10,
    filters: [],
    sortField: undefined,
    sortOrder: undefined
  },
  error: null,
  availableRequest: {
    loading: false,
    error: null
  },
  unavailableRequest: {
    loading: false,
    error: null
  }
};

export const fetchUnits = createAsyncThunk(
  'stockControl/fetchUnits',
  async ({params, accessToken}) => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + '/stock-control', {
        headers: {
          Authorization: "Bearer " + accessToken
        },
        params
      });

      return {
        response: response.data,
        params
      };
    } catch (err) {
      const errorMessage = {
        name: "fetchUnits error",
        message: err.response.data.message
      };
      throw errorMessage;
    }
  })

export const availableRequest = createAsyncThunk(
  'stockControl/available',
  async ({id, accessToken}, {rejectWithValue}) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + `/stock-control/${id}/available`,
        {},
        {
          headers: {
            Authorization: "Bearer " + accessToken
          }
        })

      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }

      return rejectWithValue(err.response.data)
    }
  })

export const unavailableRequest = createAsyncThunk(
  'stockControl/unavailable',
  async ({id, accessToken}, {rejectWithValue}) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + `/stock-control/${id}/unavailable`,
        {},
        {
          headers: {
            Authorization: "Bearer " + accessToken
          }
        })

      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }

      return rejectWithValue(err.response.data)
    }
  })

const stockControlSlice = createSlice({
  name: 'stockControl',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchUnits.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [fetchUnits.fulfilled]: (state, action) => {
      state.loading = false
      state.params = action.payload.params
      state.data.total = action.payload.response.total
      state.data.data = action.payload.response.data
    },
    [fetchUnits.rejected]: (state, action) => {
      state.loading = false
      state.error = action.error.message
    },

    [availableRequest.pending]: (state, action) => {
      state.availableRequest.loading = true
      state.availableRequest.error = null
    },
    [availableRequest.fulfilled]: (state, action) => {
      state.availableRequest.loading = false
      state.availableRequest.error = null
    },
    [availableRequest.rejected]: (state, action) => {
      state.availableRequest.loading = false
      state.availableRequest.error = action.error.message
    },

    [unavailableRequest.pending]: (state, action) => {
      state.unavailableRequest.loading = true
      state.unavailableRequest.error = null
    },
    [unavailableRequest.fulfilled]: (state, action) => {
      state.unavailableRequest.loading = false
      state.unavailableRequest.error = null
    },
    [unavailableRequest.rejected]: (state, action) => {
      state.unavailableRequest.loading = false
      state.unavailableRequest.error = action.error.message
    },
  },
})

//export const {toggleTakeActionModal} = stockControlSlice.actions

export default stockControlSlice.reducer

export const selectAllUnits = (state) => {
  return state.stockControl
}
