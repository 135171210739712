import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios";

const initialState = {
  loading: false,
  error: null,
  validationErrors: {},
};

export const billOfSaleUploadRequest = createAsyncThunk('reservations/BillOfSaleUpload',
  async ({id, file, accessToken}, {rejectWithValue}) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + `/reservations/${id}/bill-of-sale/file`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "multipart/form-data"
          }
        })

      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }

      return rejectWithValue(err.response.data)
    }
  })

const billOfSaleUploadSlice = createSlice({
  name: 'billOfSaleUpload',
  initialState,
  reducers: {
    confirmValidationErrors(state, action) {
      state.error = null
    },
    setUrl(state, action) {
      state.url = action.payload
    }
  },
  extraReducers: {
    [billOfSaleUploadRequest.pending]: (state, action) => {
      state.loading = true
      state.error = null
      state.validationErrors = []
    },
    [billOfSaleUploadRequest.fulfilled]: (state, action) => {
      state.loading = false
      state.url = action.payload.url
      state.error = null
    },
    [billOfSaleUploadRequest.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload.message
      state.validationErrors = action.payload.errors
    }
  },
})

export const {
  confirmValidationErrors, setUrl
} = billOfSaleUploadSlice.actions

export default billOfSaleUploadSlice.reducer
