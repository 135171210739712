import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'
import axios from "axios";

const CancelToken = axios.CancelToken;
let fetchCustomersCancel;

const initialState = {
  loading: false,
  data: {
    total: 0,
    data: [],
  },
  params: {
    query: null
  },
  error: null
};

export const fetchCustomers = createAsyncThunk('customersApi/fetchCustomers', async ({params, accessToken}) => {
  fetchCustomersCancel && fetchCustomersCancel();
  try {
    const response = await axios.get(process.env.REACT_APP_API_URL + '/customers', {
      headers: {
        Authorization: "Bearer " + accessToken
      },
      cancelToken: new CancelToken(function executor(c) {
        fetchCustomersCancel = c;
      }),
      params
    });

    return {
      response: response.data,
      params
    };
  } catch (err) {
    const errorMessage = {
      name: "fetchCustomers error",
      message: err.response.data.message
    };
    throw errorMessage;
  }
})

const customerApiSlice = createSlice({
  name: 'customersApi',
  initialState,
  reducers: {
    reset(state, action) {
      state.params.query = null
      state.data.total = 0
      state.data.data = []
      state.error = null
    }
  },
  extraReducers: {
    [fetchCustomers.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [fetchCustomers.fulfilled]: (state, action) => {
      state.loading = false
      state.params = action.payload.params
      state.data.total = action.payload.response.total
      state.data.data = action.payload.response.data
    },
    [fetchCustomers.rejected]: (state, action) => {
      state.loading = false
      state.error = action.error.message
    }
  },
})

export const {
  reset
} = customerApiSlice.actions

export default customerApiSlice.reducer

export const selectAllCustomers = (state) => {
  return state.customersApi.data.data
}
