import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  loading: false,
  error: null,
  validationErrors: {}
}

export const createEstimationRequest = createAsyncThunk('createEstimation/createEstimationRequest',
  async ({id, values, accessToken}, {rejectWithValue}) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + `/estimations`,
        values,
        {
          headers: {
            Authorization: "Bearer " + accessToken
          }
        })

      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }

      return rejectWithValue(err.response.data)
    }
  })

const createEstimationSlice = createSlice({
  name: 'createEstimation',
  initialState,
  reducers: {
    reset(state, action) {
      state.validationErrors = {}
    }
  },
  extraReducers: {
    [createEstimationRequest.pending]: (state, action) => {
      state.loading = true
      state.error = null
      state.validationErrors = []
      state.success = false
    },
    [createEstimationRequest.fulfilled]: (state, action) => {
      state.loading = false
      state.customer = null
      state.unit = null
      state.comment = ''
    },
    [createEstimationRequest.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload.message
      state.validationErrors = action.payload.errors
    }
  },
})

export const {reset} = createEstimationSlice.actions

export default createEstimationSlice.reducer
