import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
  loading: false,
  entrepreneurshipFilter: {
    loading: false,
    error: null,
    data: []
  },
  productFilter: {
    loading: false,
    error: null,
    data: []
  },
  productTypeFilter: {
    loading: false,
    error: null,
    data: []
  },
  data: {
    total: 0,
    data: [],
  },
  params: {
    page: 1,
    sizePerPage: 100,
    filters: [],
    sortField: undefined,
    sortOrder: undefined
  },
  error: null
};

export const fetchUnits = createAsyncThunk('unitSelector/fetchUnits', async ({params, accessToken}) => {
  try {
    const response = await axios.get(process.env.REACT_APP_API_URL + '/units', {
      headers: {
        Authorization: "Bearer " + accessToken
      },
      params
    });

    return {
      response: response.data,
      params
    };
  } catch (err) {
    const errorMessage = {
      name: "fetchUnits error",
      message: err.response.data.message
    };
    throw errorMessage;
  }
})

export const fetchEntrepreneurshipFilter = createAsyncThunk('units/fetchEntrepreneurshipFilter', async ({accessToken}) => {
  try {
    const response = await axios.get(process.env.REACT_APP_API_URL + '/entrepreneurships', {
      headers: {
        Authorization: "Bearer " + accessToken
      }
    });

    return response.data;
  } catch (err) {
    const errorMessage = {
      name: "fetchEntrepreneurshipFilter error",
      message: err.response.data.message
    };
    throw errorMessage;
  }
})

export const fetchProductFilter = createAsyncThunk('units/fetchProductFilter', async ({
                                                                                        entrepreneurship_id,
                                                                                        accessToken
                                                                                      }) => {
  try {
    const response = await axios.get(process.env.REACT_APP_API_URL + '/products', {
      headers: {
        Authorization: "Bearer " + accessToken
      },
      params: {
        entrepreneurship_id
      }
    });

    return response.data;
  } catch (err) {
    const errorMessage = {
      name: "fetchProductFilter error",
      message: err.response.data.message
    };
    throw errorMessage;
  }
})

export const fetchProductTypeFilter = createAsyncThunk('units/fetchProductTypeFilter', async ({accessToken}) => {
  try {
    const response = await axios.get(process.env.REACT_APP_API_URL + '/product-types', {
      headers: {
        Authorization: "Bearer " + accessToken
      }
    });

    return response.data;
  } catch (err) {
    const errorMessage = {
      name: "fetchProductTypeFilter error",
      message: err.response.data.message
    };
    throw errorMessage;
  }
})

const unitSelectorSlice = createSlice({
  name: 'unitSelector',
  initialState,
  reducers: {
    resetUnitSelector(state, action) {
      state.data.total = 0
      state.data.data = []
    }
  },
  extraReducers: {
    [fetchUnits.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [fetchUnits.fulfilled]: (state, action) => {
      state.loading = false
      state.params = action.payload.params
      state.data.total = action.payload.response.total
      state.data.data = action.payload.response.data
    },
    [fetchUnits.rejected]: (state, action) => {
      state.loading = false
      state.error = action.error.message
    },

    [fetchEntrepreneurshipFilter.pending]: (state, action) => {
      state.entrepreneurshipFilter.loading = true
      state.entrepreneurshipFilter.error = null
    },
    [fetchEntrepreneurshipFilter.fulfilled]: (state, action) => {
      state.entrepreneurshipFilter.loading = false
      state.entrepreneurshipFilter.data = [{
        'id': 0,
        'name': '--Todos los emprendimientos--',
        'logo_url': null
      }].concat(action.payload.data)
    },
    [fetchEntrepreneurshipFilter.rejected]: (state, action) => {
      state.entrepreneurshipFilter.loading = false
      state.entrepreneurshipFilter.error = action.error.message
    },

    [fetchProductFilter.pending]: (state, action) => {
      state.productFilter.loading = true
      state.productFilter.error = null
    },
    [fetchProductFilter.fulfilled]: (state, action) => {
      state.productFilter.loading = false
      state.productFilter.data = [{
        'id': 0,
        'name': '--Todos los productos--',
        'logo_url': null
      }].concat(action.payload.data)
    },
    [fetchProductFilter.rejected]: (state, action) => {
      state.productFilter.loading = false
      state.productFilter.error = action.error.message
    },

    [fetchProductTypeFilter.pending]: (state, action) => {
      state.productTypeFilter.loading = true
      state.productTypeFilter.error = null
    },
    [fetchProductTypeFilter.fulfilled]: (state, action) => {
      state.productTypeFilter.loading = false
      state.productTypeFilter.data = [{
        'id': 0,
        'name': '--Todos los tipos--',
        'logo_url': null
      }].concat(action.payload.data)
    },
    [fetchProductTypeFilter.rejected]: (state, action) => {
      state.productTypeFilter.loading = false
      state.productTypeFilter.error = action.error.message
    }
  },
})

export const {resetUnitSelector} = unitSelectorSlice.actions

export default unitSelectorSlice.reducer

export const selectAllUnits = (state) => {
  return state.unitSelector
}
